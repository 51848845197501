export default [
  {
    key: 'commissionNumTwo',
    label: 'field.commissionPercentage2',
    rules: 'required|decimal:2|max_value:100',
    type: 'text',
  },
  {
    key: 'commissionNumThree',
    label: 'field.commissionPercentage3',
    rules: 'required|decimal:2|max_value:100',
    type: 'text',
  },
  {
    key: 'maxTotalBet',
    label: 'field.maxTotalBet',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'minBetPerNumTwo',
    label: 'field.minBetPerNumTwo',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetPerNumTwo',
    label: 'field.maxBetPerNumTwo',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'minBetPerNumThree',
    label: 'field.minBetPerNumThree',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetPerNumThree',
    label: 'field.maxBetPerNumThree',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'winPerTwo',
    label: 'field.winPerTwo',
    rules: 'required|decimal:2|min_value:0|max_value:100',
    type: 'currency',
  },
  {
    key: 'winPerThree',
    label: 'field.winPerThree',
    rules: 'required|decimal:2|min_value:0|max_value:100',
    type: 'currency',
  },
  {
    key: 'telegramRegisterLink',
    label: 'field.telegramRegisterLink',
    rules: '',
    type: 'text',
  },
  {
    key: 'numberColors',
    label: 'field.numberColors',
    cols: 12,
  },
]
